body {
  background-color: #f8f9fa!important;
}

p {
  font-family: 'Noto Sans';
  color: #7C7C7D;
}

a{
  text-decoration: none;
}

a:hover{
  text-decoration: none;
}

h2{
  font-family: 'Noto Sans';
  color: rgb(80, 80, 80);
}

hr{
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.App {

  .flexed{
    display: flex;
  }

  .content-left{
    justify-content: left;
  }

  .site-nav{
    position: sticky;
    width: 100%;
    background-color: #f8f9fa!important;
    top: 0;
    z-index: 1000;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    
    h1{
      margin-top: .5rem;
      display: inline-block;
      font-family: 'Marck Script';
      color: #ddaa44;
      font-size: 2.5rem;
      letter-spacing: -.05rem;
      // text-shadow: -1px 0 1px #7C7C7D, 0 1px 1px #7C7C7D, 1px 0 1px  #7C7C7D, 0 -1px 1px #7C7C7D;
    }
    
    .pages-wrapper{
      margin-top: .5rem;
    }

    a.nav-item, div.nav-item{
      font-family: 'Noto Sans';
      font-size: 1.3rem;
      text-transform: uppercase;
      cursor: pointer;
    }

   a:hover, div.nav-item:hover {
      color: #DDAA44 !important; 
    }

    .nav-item.nav-link.active{
      color: #DDAA44;
    }

    .branding-initials{
      display: none;
    }
  }

  .container{
    max-width: 1200px !important;
  }

  .home{
    .fixed-call-button-wrapper{
      display: none;
    }

    a h5.leave-review{
      font-family: 'Noto Sans';
      color: rgb(80, 80, 80);
      margin:.75rem .5rem;
      display: inline-block;
      text-decoration: underline;
    }

    a h5.leave-review:hover{
      color: #ddaa44!important;
    }

    .section{
      margin: 2.5rem;
    }

    .secton-header{
      margin: .3rem 0 1.5rem 0;
    }
    
    .review{
      padding: 0 0 .5rem 0;

      .card{
        height:300px;
      }

      .card-body{
        overflow: scroll;
      }

      h4{
          color: #ddaa44;
          font-family: 'Marck Script';
          display: inline-block;
      }

      h6{
        margin: 0 .5rem;
        display: inline-block;
      }  
    }

    .imageGallery{
      max-width: 10in;
      margin: auto;

      .image-gallery-svg:hover,.image-gallery-icon:hover {
        color: #ddaa44;
      }

      
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover {
        border: 4px solid #ddaa44;
      }

      
    }

    
  }

  .contact-us{

    .map-wrapper{
      max-width: 1200px;
      margin:auto;
    }

    .business-hours-day{
      font-size: 1.3rem;
      font-weight: 500;
    }

    .get-directions-button-wrapper{
      display: inline-block;
      background-color: #ddaa44;
      border-radius: 3px;
      border: solid 1px rgb(80, 80, 80);

      .get-directions-button{
        padding: 10px;
        color: rgb(80, 80, 80);
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }

    .contact-us-phone{
      font-size: 1.3rem;
      font-weight: 500;
      color: #ddaa44;
    }

    .contact-us-address{
      font-size: 1.3rem;
      font-weight: 500;
    }

    .p-top-line{
      margin-bottom:0;
    }

  }
  .site-footer{
    p {
      margin-bottom: 0px
    }

    .footer-phone-and-address-wrapper, .ooo-wrapper{
      display: inline-block;
      text-align: left;
      margin: 5px 25px;

      .footer-phone{
        color: #ddaa44;
      }
    }
  }
}

@media (max-width: 991px) { 
  .App{
    .home{
      .fixed-call-button-wrapper{
        z-index: 4;
        display: inline-block;
        background-color: #ddaa44;
        border-radius: 3px;
        border: solid 1px rgb(80, 80, 80);
        position: fixed;
        right: 55px;
        bottom: 25px;
        
        .fixed-call-button{
          padding: 15px;
          color: rgb(80, 80, 80);
          display: inline-block;
          font-size: 1.3rem;
          font-weight: 500;
        }
      }

    }

    .site-nav{
      .navbar{
        align-items: flex-start;
      }
      
      .phone-link{
        display: none;
      }

    }

  }

}

@media (max-width: 560px) { 
  .App{
    .home{
      .fixed-call-button-wrapper{
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 75%;
        text-align: center;
        
        .fixed-call-button{

        }
      }

    }

    .site-nav{
      .branding-full{
        display: none;
      }

      .branding-initials{
        display: inline-block;
      }

    }

    .site-footer{
      .container{
        display: block;
      }
    }

    .contact-us{
      .contact-details-wrapper{
        display:block;
      }
    }

  }

}
